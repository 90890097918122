<template>
  <BaseDataTable
    v-bind="defaultAttributes"
    :dataLoading="dataLoading"
    :columns="columns"
    :data="getData"
    :total-rows="rowsLength"
    :has-summary="false"
    :compact="true"
    class="no-borders payroll-history"
    @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #html-row="{ htmlData }">
      <tr class="content-row">
        <td :colspan="columns.length">
          <div class="font-semibold">{{ $t('Please verify the following employer information') }}:</div>
          <dl class="space-y-4 mt-4">
            <dt class="flex space-x-2">
              <span class="font-semibold">{{ $t('Employer Name & Address') }}</span>
              <div>
                <div>
                  {{ htmlData.employer.name }}
                </div>
                <div>
                  {{ htmlData.employer.address?.address_1 }}
                </div>
                <div>
                  {{ htmlData.employer.address?.city }} {{ htmlData.employer.address?.state }} {{ htmlData.employer.address?.postal_code }}
                </div>
              </div>
            </dt>
            <dt class="flex space-x-2">
              <span class="font-semibold">{{ $t('Employer Federal ID Number(EIN)') }}</span>
              <div>
                {{ htmlData.employer.federal_employer_id }}
              </div>
            </dt>
            <dt class="flex space-x-4">
              <span class="font-semibold">{{ $t('Employer State Account Numbers') }}</span>
              <div class="grid grid-cols-2 gap-x-4">
                <div>{{ $t('State')  }}</div>
                <div>{{ $t('Account')  }}</div>
                <template v-for="state of htmlData.employer.states">
                  <div>{{ state.code }}</div>
                  <div>{{ state.employer_number ||  '**********'}}</div>
                </template>
              </div>
            </dt>
          </dl>
        </td>
      </tr>
    </template>
    <template #header="{ row }">
      <div
        v-if="row?.header?.employee"
        class="flex items-inline-base"
      >
        <EmployeeLink
          :data="row.header?.employee"
          :show-preview="false"
          class="min-w-[400px]"
        />
        <BaseAddressLink
          :value="row.header?.address"
          class="mx-2"
        />

        <span>{{ $t('SS#') }}</span>
        <span class="mx-2">
          {{ getFormattedSSN(row.header?.employee?.ssn) }}
        </span>
        <BasePhoneLink
          :value="row.header?.employee?.phone_1"
          :phoneMask="'(###)###-####'"
          :no-data-text="''"
        />
      </div>
    </template>

    <template #federal.fit="{ row }">
      <dl class="w-full">
        <dt class="flex justify-between">
          <span class="font-semibold">{{ $t('BOX 1(fit wage)') }}</span>
          <span>{{ $formatPrice(row.federal.fit_wages) }}</span>
        </dt>
        <dt class="flex justify-between">
          <span class="font-semibold">{{ $t('BOX 2(fit w/h)') }}</span>
          <span>{{ $formatPrice(row.federal.fit_wh) }}</span>
        </dt>
      </dl>
    </template>

    <template #federal.fica="{ row }">
      <dl class="w-full">
        <dt class="flex justify-between">
          <span class="font-semibold">{{ $t('BOX 3(fica wage)') }}</span>
          <span>{{ $formatPrice(row.federal.fica_wages) }}</span>
        </dt>
        <dt class="flex justify-between">
          <span class="font-semibold">{{ $t('BOX 4(fica w/h)') }}</span>
          <span>{{ $formatPrice(row.federal.fica_wh) }}</span>
        </dt>
      </dl>
    </template>

    <template #federal.medicare="{ row }">
      <dl class="w-full">
        <dt class="flex justify-between">
          <span class="font-semibold">{{ $t('BOX 5(med wage)') }}</span>
          <span>{{ $formatPrice(row.federal.medicare_wages) }}</span>
        </dt>
        <dt class="flex justify-between">
          <span class="font-semibold">{{ $t('BOX 6(med w/h)') }}</span>
          <span>{{ $formatPrice(row.federal.medicare_wh) }}</span>
        </dt>
      </dl>
    </template>

    <template #federal.retirement="{ row }">
      <dl class="w-full">
        <dt class="flex justify-between">
          <span class="font-semibold">{{ $t('BOX 12D(401k)') }}</span>
          <span>{{ $formatPrice(row.federal.retirement) }}</span>
        </dt>
      </dl>
    </template>

    <template #states_local="{ row }">
      <template v-for="stateGroup of row.states">
        <div class="font-medium italic mt-2">
          {{ stateGroup.state.code }} ({{ stateGroup.state.description }})
        </div>
        <dl class="w-full">
          <dt class="flex justify-between">
            <span class="font-semibold">{{ $t('BOX 14(sdi w/h)') }}</span>
            <span>{{ $formatPrice(stateGroup.totals.sdi_wh) }}</span>
          </dt>
          <dt class="flex justify-between">
            <span class="font-semibold">{{ $t('BOX 16(sit wage)') }}</span>
            <span>{{ $formatPrice(stateGroup.totals.sit_wages) }}</span>
          </dt>
          <dt class="flex justify-between">
            <span class="font-semibold">{{ $t('BOX 17(sit w/h)') }}</span>
            <span>{{ $formatPrice(stateGroup.totals.sit_wh) }}</span>
          </dt>
          <!-- <dt class="flex justify-between">
            <span class="font-semibold">{{ $t('BOX 18(loc wage)') }}</span>
            <span>{{ $formatPrice(stateGroup.totals.loc_wages) }}</span>
          </dt>
          <dt class="flex justify-between">
            <span class="font-semibold">{{ $t('BOX 19(loc w/h)') }}</span>
            <span>{{ $formatPrice(stateGroup.totals.loc_wh) }}</span>
          </dt> -->
        </dl>
      </template>
    </template>

    <template #subtotal="{ subtotal }">
      <td>
        <span class="font-bold">{{ $t('Grand Totals') }}</span>
      </td>
      <td>
        <dl class="w-full">
          <dt class="flex justify-between">
            <span class="font-semibold">{{ $t('BOX 1(fit wage)') }}</span>
            <span>{{ $formatPrice(subtotal.federalTotals.fit_wages) }}</span>
          </dt>
          <dt class="flex justify-between">
            <span class="font-semibold">{{ $t('BOX 2(fit w/h)') }}</span>
            <span>{{ $formatPrice(subtotal.federalTotals.fit_wh) }}</span>
          </dt>
        </dl>
      </td>
      <td>
        <dl class="w-full">
          <dt class="flex justify-between">
            <span class="font-semibold">{{ $t('BOX 3(fica wage)') }}</span>
            <span>{{ $formatPrice(subtotal.federalTotals.fica_wages) }}</span>
          </dt>
          <dt class="flex justify-between">
            <span class="font-semibold">{{ $t('BOX 4(fica w/h)') }}</span>
            <span>{{ $formatPrice(subtotal.federalTotals.fica_wh) }}</span>
          </dt>
        </dl>
      </td>
      <td>
        <dl class="w-full">
          <dt class="flex justify-between">
            <span class="font-semibold">{{ $t('BOX 5(med wage)') }}</span>
            <span>{{ $formatPrice(subtotal.federalTotals.medicare_wages) }}</span>
          </dt>
          <dt class="flex justify-between">
            <span class="font-semibold">{{ $t('BOX 6(med w/h)') }}</span>
            <span>{{ $formatPrice(subtotal.federalTotals.medicare_wh) }}</span>
          </dt>
        </dl>
      </td>
      <td>
        <dl class="w-full">
          <dt class="flex justify-between">
            <span class="font-semibold">{{ $t('BOX 12D(401k)') }}</span>
            <span>{{ $formatPrice(subtotal.federalTotals.retirement) }}</span>
          </dt>
        </dl>
      </td>
      <td>
        <template v-for="stateTotal of subtotal.stateTotals">
          <div class="font-medium italic mt-2">
            {{ stateTotal.state.code }} ({{ stateTotal.state.description }})
          </div>
          <dl class="w-full">
            <dt class="flex justify-between">
              <span class="font-semibold">{{ $t('BOX 14(sdi w/h)') }}</span>
              <span>{{ $formatPrice(stateTotal.totals.sdi_wh) }}</span>
            </dt>
            <dt class="flex justify-between">
              <span class="font-semibold">{{ $t('BOX 16(sit wage)') }}</span>
              <span>{{ $formatPrice(stateTotal.totals.sit_wages) }}</span>
            </dt>
            <dt class="flex justify-between">
              <span class="font-semibold">{{ $t('BOX 17(sit w/h)') }}</span>
              <span>{{ $formatPrice(stateTotal.totals.sit_wh) }}</span>
            </dt>
            <dt class="flex justify-between">
              <span class="font-semibold">{{ $t('Employees') }}</span>
              <span>{{ stateTotal.totals.employeesCount }}</span>
            </dt>
          </dl>
        </template>
        <div class="font-medium italic mt-2">
          {{ $t('Total State/Local') }}
        </div>
        <dl class="w-full">
          <dt class="flex justify-between">
            <span class="font-semibold">{{ $t('BOX 14(sdi w/h)') }}</span>
            <span>{{ $formatPrice(subtotal.totalStateLocal.sdi_wh) }}</span>
          </dt>
          <dt class="flex justify-between">
            <span class="font-semibold">{{ $t('BOX 16(sit wage)') }}</span>
            <span>{{ $formatPrice(subtotal.totalStateLocal.sit_wages) }}</span>
          </dt>
          <dt class="flex justify-between">
            <span class="font-semibold">{{ $t('BOX 17(sit w/h)') }}</span>
            <span>{{ $formatPrice(subtotal.totalStateLocal.sit_wh) }}</span>
          </dt>
        </dl>
      </td>
    </template>
  </BaseDataTable>
</template>
<script>
import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'
import {
  QuarterlyStateUnemploymentOptions
} from '@/modules/payroll/components/reports/util'
import { getFormattedSSN, getHiddenSSN } from "@/components/form/ssnUtils";

export default {
  extends: ReportTableWrapper,
  props: {
    dataLoading: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object
    }
  },
  data() {
    return {
      getFormattedSSN,
      QuarterlyStateUnemploymentOptions
    }
  },
  computed: {
    reportOptions() {
      const nameFormat = this.filters?.nameFormat || this.$route.query?.nameFormat || QuarterlyStateUnemploymentOptions.NameFormats.FirstLast
      const include = this.filters?.include || this.$route.query?.include || QuarterlyStateUnemploymentOptions.Include.None

      return {
        nameFormat,
        include,
      }
    },
    columns() {
      return [
        {
          label: this.$t('Employee'),
          prop: '',
          minWidth: 70,
          maxWidth: 70,
        },
        {
          label: this.$t('FIT'),
          prop: 'federal.fit',
          minWidth: 180,
          maxWidth: 200,
        },
        {
          label: this.$t('FICA'),
          prop: 'federal.fica',
          minWidth: 180,
          maxWidth: 200,
        },
        {
          label: this.$t('Medicare'),
          prop: 'federal.medicare',
          minWidth: 180,
          maxWidth: 200,
        },
        {
          label: this.$t('Retirement'),
          prop: 'federal.retirement',
          minWidth: 180,
          maxWidth: 200,
        },
        {
          label: this.$t('State<br>Local'),
          prop: 'states_local',
          minWidth: 180,
          maxWidth: 200,
        }
      ]
    },
  },
  methods: {
    addEmployerInfo() {
      const employer = this.data.items.employer
      employer.name = employer.name || this.data?.header?.company?.name

      this.rows.push({
        htmlData: {
          employer,
        }
      })
    },
    addEmployeeGroup(employeeGroup) {
      const {
        employee,
      } = employeeGroup

      this.rows.push({
        header: {
          employee,
          address: employee?.address || {},
        },
      })

      this.rows.push(employeeGroup)
    },
    addGrandTotals() {
      const federalTotals = this.data.items.w3_totals
      const stateTotals = Object.values(this.data.totals) // [{state, totals}]
      const allEmployees = this.data.items.employees || []

      stateTotals.forEach(stateTotalsPair => {
        const employeesCount = allEmployees.filter(employee => {
          return employee.states.some(stateGroup => stateGroup?.state?.id === stateTotalsPair?.state?.id)
        }).length

        stateTotalsPair.totals.employeesCount = employeesCount
      })

      const totalStateLocal = stateTotals.reduce((acc, state) => {
        return {
          sdi_wh: acc.sdi_wh + state.totals.sdi_wh,
          sit_wages: acc.sit_wages + state.totals.sit_wages,
          sit_wh: acc.sit_wh + state.totals.sit_wh,
          loc_wages: acc.loc_wages + state.totals.loc_wages,
          loc_wh: acc.loc_wh + state.totals.loc_wh,
        }
      }, {
        sdi_wh: 0,
        sit_wages: 0,
        sit_wh: 0,
        loc_wages: 0,
        loc_wh: 0,
      })

      this.rows.push({
        subtotal: {
          federalTotals,
          stateTotals,
          totalStateLocal,
        }
      })
    },
    composeRows(data) {

      this.addEmployerInfo()

      data.employees.forEach(this.addEmployeeGroup)

      this.addGrandTotals()

      return this.rows
    },
  }
}
</script>
