<template>
  <div>
    <template v-if="currentResource.id">
      <portal to="page-title">
        {{ $t('Service Invoice #') }}
        {{ currentResource.attributes.number }} @
        <router-link :to="`/accounts-receivable/customers/${customer.id}/view`">
          {{ customer.code }}
          <span v-if="customer?.name">({{ customer.name }})</span>
        </router-link>
      </portal>
      <div class="flex justify-between items-center">
        <layout-tabs :items="filteredItems"/>
        <div class="flex items-center">
          <PostAction
              v-if="$isAuthorized($actionPolicies.Post, currentResource)"
              :id="currentResource.id"
              entity-name="Billing"
              resource-name="billings"
              @on-action-callback="id => getCurrentResource(id, true)"
          />
          <VoidAction
              v-if="$isAuthorized($actionPolicies.Void, currentResource)"
              :id="currentResource.id"
              entity-name="Billing"
              resource-name="billings"
              @on-action-callback="id => getCurrentResource(id, true)"
          />
          <TableShareButton
              v-if="authorizedToShare"
              :link="getSharableLink"
          />

          <StimulsoftPrintButton
            :url="`/restify/billings/${currentResource.id}/stimulsoft`"
            :entity="StimulsoftPrintEntities.ServiceInvoice"
            :extraData="extraStimulsoftData"
            :name="pageTitle"
            @template-obj-changed="selectedTemplateObj = $event"
          >

            <template
              v-if="showPrintAdditionalCheckbox"
              #extra-data
            >
              <BaseCheckbox
                v-model="extraStimulsoftData.flags.print_additional_info"
                :label="$t('Print additional information (Call Placed By and Purchase Order #)')"
                id="print_additional_info"
                class="col-span-6"
              />
            </template>

          </StimulsoftPrintButton>

          <SendEmailBillingAction
              :billing="currentResource"
              :entity="StimulsoftPrintEntities.ServiceInvoice"
              @save="getCurrentResource(currentResource.id, true)"
          />

        </div>
      </div>
      <portal to="entity-name">
        {{ currentResource.attributes.number }}
      </portal>
    </template>
    <router-view :key="renderKey"/>
  </div>
</template>
<script lang="ts">
  import layoutPermissions from '@/mixins/layoutPermissions'
  import { billingTypes } from '@/modules/accounts-receivable/pages/billings/billings'
  import BillingPrintAction from '@/modules/accounts-receivable/layout/billings/BillingPrintAction.vue'
  import SendEmailBillingAction from "@/modules/accounts-receivable/components/billings/SendEmailBillingAction.vue";
  import { defineComponent } from 'vue';
  import { resourceStatuses } from "@/enum/enums";
  import {isBetaTestingOrganization} from "@/modules/common/config";
  import {StimulsoftPrintEntities} from "@/enum/stimulsoft";

  export default defineComponent({
    mixins: [layoutPermissions],
    components: {
      SendEmailBillingAction,
      BillingPrintAction,
    },
    props: {
      billingType: {
        type: String,
        default: billingTypes.Service,
      },
    },
    data() {
      return {
        renderKey: crypto.randomUUID(),
        StimulsoftPrintEntities,
        selectedTemplateObj: null as any,
        extraStimulsoftData: {
          flags: {
            print_additional_info: false,
          }
        },
      }
    },
    computed: {
      items() {
        return [
          {
            name: this.$t('Details'),
            path: `/service-billing/service-invoices/${this.$route.params.id}/view`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Edit'),
            path: `/service-billing/service-invoices/${this.$route.params.id}/edit`,
            authorizedTo: 'authorizedToUpdate',
          },
        ]
      },
      customer() {
        const { customer: customer = {} } = this.currentResource
        return {
          ...customer.attributes,
        }
      },
      currentResource() {
        return this.$store.state.accountsReceivable.currentBilling
      },
      isPosted() {
        return this.currentResource?.attributes?.status === resourceStatuses.Posted
      },
      pageTitle() {
        const number = this.currentResource?.attributes?.number || ''
        const customerCode = this.customer?.code || ''
        return `${this.$t('Invoice')} ${number} @ ${customerCode}`
      },
      authorizedToShare() {
        return this.isDetailsPage && this.billingType === billingTypes.Progress
      },
      getSharableLink() {
        return `${window.origin}/service-billing/service-invoices/${this.currentResource.id}`
      },
      showPrintAdditionalCheckbox() {
        return this.selectedTemplateObj?.name === 'Service Invoice - Envelope'
      }
    },
    methods: {
      isBetaTestingOrganization,
      async getCurrentResource(id: string, redirectToViewPage = false) {
        if (!id) {
          id = this.$route.params.id
        }

        await this.$store.dispatch('accountsReceivable/getBilling', id)

        this.renderKey = crypto.randomUUID()

        if (!redirectToViewPage) {
          return
        }
        this.$router.push(`/service-billing/service-invoices/${this.$route.params.id}/view`)
      },
    },
  })
</script>

